body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  text-align: center;
  font-size: 40px;
  color: '#00000';
  font-family: 'PT Sans', sans-serif;
  font-weight: bold;
}

li a {
  margin-left: 0px;
  font-size: 13px;
}

p a{
  margin-left: 0px;
}

.card{
  margin-left: 40;
}

.card-title{
  margin-top: 1.5rem;
}

.bgImage{
  background-image: url('./images/vias.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
}

.titulo{
  color: black;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300px;
  font-size: 50px;
}

.inicarSesion{
  font-family: 'Karla', sans-serif;
  font-size: 30px;
}

.card{
  border: 1px;
  border-radius: 25px;
  border-radius: 54px 54px 54px 54px;
  -moz-border-radius: 54px 54px 54px 54px;
  -webkit-border-radius: 54px 54px 54px 54px;
  border: 0px solid #000000;
}

a{
  margin-left: 320px;
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
}

.principal{
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 250px;
  padding: 2rem;
}

iframe{
  height: 85vh;
}

.imagenTop{
  width: 349px;
  height: 230px;
  border-radius: 20px 20px 0px 0px;
  -moz-border-radius: 20px 20px 0px 0px;
  -webkit-border-radius: 20px 20px 0px 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.58), rgba(0, 0, 0, 0.58)), url(images/maya.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.logo{
  height: 100%;
  width: 100%;
  margin: 0px;
}

.pyramid{
  background: rgba(0, 0, 4, 0.7);
}

.form-usuario {
  height: 100vh;
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor-form {
  padding: 0;
  max-width: 349px;
  width: 80%;
  height: 573px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 0px solid #000000;
}

.contenedor2{
  padding: 1rem;
  height: 750px;
  width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgb(255, 248, 243);
  border-radius: 1rem;
  border-radius: 44px 44px 44px 44px;
  -moz-border-radius: 44px 44px 44px 44px;
  -webkit-border-radius: 44px 44px 44px 44px;
}

.campo-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  align-items: center;
}

.titulo{
  margin-bottom: 3rem;
  color: black;
}

.spinner{
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin-top: 20%;
}

.login{
  width: 349px;
  height: 79px;
  border-radius: 1rem;
  border-radius: 0px 0px 20px 20px;
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px;
  background-color: #0A6CB4;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  letter-spacing: 0.9px;
}

.navbar{
  display: flex;
  justify-content: space-between;
  height: 5rem;
  margin-bottom: 0.1rem;
  background-color: #06316A;
}

@media screen and (max-width: 768px){
  .navbar{
    display: row;
    justify-content: space-between;
    height: 6rem;
    margin-bottom: 1rem;
    background-color: #06316A;
    padding: 1rem;
  }
  @media screen and (max-width: 425px){
    .navbar{
      display: column;
      justify-content: space-between;
      align-items: center;
      height: 8rem;
      margin-bottom: 1rem;
      background-color: #06316A;
    }
  }
}

.logout{
  font-weight: bold;
  width: 6rem;
  border-radius: 5px;
  border: none;
}

.powered{
  color: white;
  font-size: medium;
  margin: 0;
  padding-top: 22px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.powered_container{
  display: flex;
  width: 20rem;
  padding-left: 1rem;
}

.powered_deep{
  height: 60px;
  width: 130px;
}

.powerBi {
  height: 800px;
  width: 100%;
}